import { Controller } from "stimulus";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
Exporting(Highcharts);

export default class extends Controller {
  static values = {
    data: Object,
    series: Array,
    jobTitles: Array,
  };

  connect() {
    Highcharts.chart(this.element, {
      chart: {
        type: "column",
        height: 600,
      },
      title: "Calificación Departamento",
      xAxis: {
        categories: this.jobTitlesValue,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      credits: {
        enabled: false,
      },
      series: this.seriesValue,
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
    });
  }
}
