import { Controller } from "stimulus"
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
export default class extends Controller {
  static values = {
   operariosScoreInfo: Object
  }

  connect() {
    const data = this.operariosScoreInfoValue;

    const series = [
      {
        name: data.last_survey_year,
        data: data.last_survey,
        color: '#AA1E60'
      },
      {
        name: data.second_survey_year,
        data: data.second_survey,
        color: '#794545'
      },
      { name: data.survey_year,
      data: data.survey,
      color: '#FF6666'
    }]

    Highcharts.chart(this.element, {
      chart: {
        type: 'column',
        height: 600
      },
      title: {
        text: 'Calificación Por Operarios'
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 25,
        title: {
          text: 'Calificación (%)'
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        },
        series: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: series,
      credits: {
        enabled: false
      }
    });
  }
}