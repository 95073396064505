import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import {get} from "@rails/request.js";

export default class extends Controller {
    static values = {
        url: String
    }

    click(event) {
        get(this.urlValue, {
            responseKind: "turbo-stream"
        })
    }
}