// welcome_message_controller.js
import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import $ from 'jquery'

export default class extends Controller {
    static values = {
        url: String
    }

    connect() {
        var url = this.urlValue

        document.getElementById('employee-container').addEventListener('scroll', event => {
            var ulEmployees = document.getElementById('employees-list')
            var employeesCount = ulEmployees.childElementCount
            var limit = employeesCount + 10

            const {scrollHeight, scrollTop, clientHeight} = event.target;

            if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {

                let params = new URLSearchParams
                params.append('limit', limit)

                get(`${url}?${params}`, {
                    responseKind: "turbo-stream",
                    data: params
                })
            }
        });
    }
}
