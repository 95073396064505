// welcome_message_controller.js
import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import $ from 'jquery'

export default class extends Controller {
  static values = {
    id: Number
  }

  connect() {
    if(this.idValue === 0) {
      $('#employee_branch_office_id').prop("disabled", false)
    }
    else if(this.idValue > 0){
      $('#employee_branch_office_id').prop("disabled", true)
    }
  }

  change(event) {
    const branchOfficeId = event.target.selectedOptions[0].value

    get(`/branch_offices/${branchOfficeId}/departments`, {
      responseKind: "turbo-stream"
    })

  }
}
