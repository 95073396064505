import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
export default class extends Controller {
  select(event) {
    const select_all = event.target.checked
    var checkboxes = document.getElementsByClassName("children-check");

    if (select_all === true) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  }
}
