import { Controller } from "@hotwired/stimulus"
import select2 from "select2";
import $ from 'jquery'

export default class extends Controller {
    static values = {
        url: String
    }
    click(event) {
        var branch_offices = $('#branch_office_id :selected').map(function () {
            return $(this).text();
        }).get().join(', ');

        var departments = $('#department_id :selected').map(function () {
            return $(this).text();
        }).get().join(', ');

        var segments = $('#section_id :selected').map(function () {
            return $(this).text();
        }).get().join(', ');

        var job_titles = $('#job_title :selected').map(function () {
            return $(this).text();
        }).get().join(', ');

        $("#selected_branch_offices").text(branch_offices)
        $("#selected_departments").text(departments)
        $("#selected_segments").text(segments)
        $("#selected_job_titles").text(job_titles)

        $('#filter-menu').addClass("hiding")
        $('div .offcanvas-backdrop ').removeClass("show")
        $("body").css('overflow','')
    }
}

