import { Controller } from "stimulus"
import {post} from "@rails/request.js";

export default class extends Controller {
    static values = {
        modal: String,
        name: String,
        lastName: String,
        email: String,
        phone: String,
        department: String,
        gender: String,
        jobTitle: String,
    }

    static targets = ["form"]

    submit( event ) {
        const form = this.formTarget
        var modal = document.getElementById(this.modalValue)
        let params = new URLSearchParams

        var name = document.getElementById(this.nameValue)
        var lastname = document.getElementById(this.lastNameValue)
        var email = document.getElementById(this.emailValue)
        var phone = document.getElementById(this.phoneValue)
        var department = document.getElementById(this.departmentValue)
        var gender = document.getElementById(this.genderValue)
        var jobtitle = document.getElementById(this.jobTitleValue)

        params.append('name', name.value)
        params.append('last_name', lastname.value)
        params.append('email', email.value)
        params.append('phone', phone.value)
        params.append('department_id', department.value)
        params.append('gender', gender.value)
        params.append('job_title', jobtitle.value)

        post(`${this.formTarget.action}?${params}`, {
            responseKind: "turbo-stream",
            data: params
        }).then(function(response){
            if ( response.statusCode == 200) {
                var modalBackdrop = document.getElementsByClassName('modal-backdrop fade show')
                for(var i = 0; i < modalBackdrop.length; i++) {
                    modalBackdrop[i].style.display = 'none'
                }
                modal.classList.remove("show")
                document.getElementsByTagName("body")[0].removeAttribute("style");
            }
            else {
                form.reset()
            }
        })
    }

}
