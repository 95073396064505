import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"
import Swal from 'sweetalert2'

export default class extends Controller {
    static values = {
        url: String,
        loader: String
    }
    static targets = ["form"]

    connect() {

    }

    export( event) {
        var loader = document.getElementById('backdrop-export')
        loader.classList.remove("loader-visibility");

        navigator.submitForm(this.formTarget)
    }
}