import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static values = {
    survey: String,
  }

  selectSurvey(event) {
    const survey = document.getElementById('survey_id')
    document.getElementById('title').value = "Copy of "+survey.options[survey.selectedIndex].text
  }

  select(event) {
    const select_all = document.getElementById('select_all').checked
    var checkboxes = document.getElementsByClassName("children-check");

    if (select_all === true) {
      for(var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
      }
    }
    else {
      for(var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  }

  click(event) {
    const survey = document.getElementById('survey_id')
    survey.value = this.surveyValue
    document.getElementById('title').value = "Copy of "+survey.options[survey.selectedIndex].text
  }

  clear(event) {
    document.getElementById('survey_id').value = ""
    document.getElementById('title').value = ""
  }
}

