import { Controller } from "@hotwired/stimulus"
import Sortable  from 'sortablejs'
import bootstrapTable from 'bootstrap-table'

export default class extends Controller {
    static values = {}
    connect() {
        $(".job-title-table-sort").bootstrapTable({
            sortName: 'total',
            sortOrder: 'desc'
        })
    }
}