import { Controller } from "@hotwired/stimulus"
import select2 from "select2";
import $ from 'jquery'
export default class extends Controller {
    static values = {
        children: String,
        select: String
    }

    select(event) {
        const select_all = event.target

        if(select_all.checked === true) {
            $("."+this.childrenValue+" > option").prop("selected","selected");
            $("."+this.childrenValue).trigger("change");
        }
        else
        {
            $('.'+this.childrenValue).select2().val('').trigger('change');
        }

        $("#"+this.selectValue).select2({
            closeOnSelect: false
        })

        var selected =   $("#"+this.selectValue).val().length;
        var of = $("#"+this.selectValue).find('option').length;

        if (selected > 10)
            $("#"+this.selectValue).parent().find('.select2 .select2-selection ul').html('Selected ' + selected + '/ ' + of )
    }

    selectAll(event) {
        const select_all = event.target

        var selected = $('#branch_office_id').val().length;
        var of = $('#branch_office_id').find('option').length;

        if (selected > 10)
            $('#branch_office_id').parent().find('.select2 .select2-selection ul').html('Selected ' + selected + '/ ' + of )


        if(select_all.checked === true) {
            $("#branch_office_id > option").prop("selected", "selected");
            $("#branch_office_id").trigger("change");
        }
        else
        {
            $('#branch_office_id').select2().val('').trigger('change');
        }

        var ev = new Event('change');
        document.getElementById('branch_office_id').dispatchEvent(ev)

        $("#branch_office_id").select2({
            closeOnSelect: false
        })
    }
}
