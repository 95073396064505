import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element)
  }

  hide(event) {
    if (event.detail.success) {
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }
    else{
      this.modal.show()
    }
  }

  isOpen(event) {
    if (event.detail.success) {
      this.modal.show()
    }
  }
}

