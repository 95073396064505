import { Controller } from "stimulus";
import { Tab } from 'bootstrap'

export default class extends Controller {
  static targets = ['next']

  get sections() {
    return [...document.getElementsByClassName('nav-link')]
  }

  activeSection() {
    return document.querySelector('#v-pills-tab .nav-link.active')
  }

  activeSectionIndex() {
    return this.sections.indexOf(this.activeSection())
  }

  next(event) {
    event.preventDefault();

    if (this.lastSection()) {
      this.nextTarget.classList.add('disabled')
    } else {
      let sectionIndex = this.activeSectionIndex() + 1
      var nextSection = this.sections[sectionIndex]
      var tab = new Tab(nextSection)

      tab.show()
    }
  }

  lastSection() {
    return this.activeSectionIndex() == this.sections.length - 1
  }
}
