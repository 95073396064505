import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["percentage", "totalProgress"]
  static values = {
     sectionNames: Array
   }

  connect() {
    document.addEventListener('answerEvent', this.calculateProgress.bind(this));
    this.calculateProgress();
  }

  calculateProgressForSection(section) {
    let sectionPercentage = document.getElementById(`section-${section}`)
    let sectionAnswers = document.getElementsByClassName(section);
    let answeredQuestions = Array.from(document.getElementsByClassName(section)).filter(e => e.dataset['answered'] === 'true')
    let sectionProgress = parseInt((100 * answeredQuestions.length) / sectionAnswers.length)

    sectionPercentage.textContent = sectionProgress + "%";

    return sectionProgress;
  }

  calculateProgress() {
    let sectionsTotalProgress = 0;
    this.sectionNamesValue.forEach(section => {
      sectionsTotalProgress += this.calculateProgressForSection(section);
    });

    let totalProgress = Math.round(sectionsTotalProgress/ this.sectionNamesValue.length);

    this.totalProgressTarget.textContent =  totalProgress + "%";
  }
}
