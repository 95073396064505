import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        choice: String,
        button: String,
        div: String,
        ul: String,
        category: String,
        formId: String,
        title: String,
        conditions: Array
    }

    getTypeOfQuestion(event) {
        var selectedType = event.target
        const btnType = document.getElementById(this.buttonValue)
        var inputType = document.getElementById(this.categoryValue)
        var ulAnswers = document.getElementById(this.ulValue)
        const addChoiceButton = document.getElementById(this.divValue)

        inputType.value = selectedType.value
        btnType.innerText = selectedType.innerText

        if(ulAnswers.childElementCount > 0) {
            for(var i= 0; i < ulAnswers.childNodes.length ; i++) {
                if (selectedType.value === 'multiple_choice') {
                    ulAnswers.getElementsByClassName('q-category')[i].setAttribute('class','fe fe-circle text-gray-500 h3 text-center col-auto q-category')
                    addChoiceButton.removeAttribute('hidden')
                    this.updateChoiceInputStyle(ulAnswers.childNodes[i].id)
                } else if (selectedType.value === 'check_box') {
                    ulAnswers.getElementsByClassName('q-category')[i].setAttribute('class','fe fe-check-square text-gray-500 h3 text-center col-auto q-category')
                    addChoiceButton.removeAttribute('hidden')
                    this.updateChoiceInputStyle(ulAnswers.childNodes[i].id)
                } else if (selectedType.value === 'free_text') {
                    ulAnswers.innerHTML = '';
                    addChoiceButton.setAttribute('hidden', 'hidden')
                    this.createFreeText()
                }
            }
        }
        else {
            if( selectedType.value === 'free_text'){
                ulAnswers.innerHTML = '';
                this.createFreeText()
                addChoiceButton.setAttribute('hidden', 'hidden')
            }
            else {
                this.createChoice(selectedType.value)
                addChoiceButton.removeAttribute('hidden')
            }
        }
    }

    updateChoiceInputStyle(li) {
        var choicesCount = 0
        var ulAnswers = document.getElementById(this.ulValue)
        var index = 0

        choicesCount = ulAnswers.childElementCount
        index = choicesCount - 1

        var liChoice = document.getElementById(li)
        var divPrincipal = liChoice.querySelector('div[name="principal-block"]')

        var divChoice = liChoice.querySelector('div[name="div-choice"]')
        if (divChoice.className === "col-10 align-items-center flex-nowrap div-choice-block") {
            divChoice.removeAttribute('class')
            divChoice.setAttribute('class',"col-6 align-items-center flex-nowrap div-choice-block");
        }

        var inputChoice = divChoice.querySelector('input[id="values"]')
        inputChoice.removeAttribute('placeholder')
        inputChoice.setAttribute('placeholder', 'Choice '+ choicesCount)

        var divCondition = liChoice.getElementsByClassName('div-condition-block')
        if (divCondition.length <= 0) {
            var newDivCondition = document.createElement('div')
            newDivCondition.id = 'div-condition-'+choicesCount
            newDivCondition.className = 'col-5 align-items-center flex-nowrap div-condition-block'
            var selectCondition = document.createElement('select')
            selectCondition.name = "values["+index+"][condition]"
            selectCondition.className = 'form-select'

            this.conditionsValue.forEach(option => {
                const optionElement = document.createElement("option");
                optionElement.value = option[1];
                optionElement.textContent = option[0];
                selectCondition.appendChild(optionElement);
            });
            newDivCondition.appendChild(selectCondition)
            divPrincipal.appendChild(newDivCondition)
        }
    }

    createChoice(typeOfQuestion) {
        var choicesCount = 0
        var ulAnswers = document.getElementById(this.ulValue)
        var index = 0

        choicesCount = ulAnswers.childElementCount + 1
        index = choicesCount - 1

        var li = document.createElement('li')
        li.id = 'choice-item-'+choicesCount
        li.className = 'list-group-item pb-1 pt-1'
        li.setAttribute('data-controller', 'types-of-question')
        li.setAttribute('data-types-of-question-choice-value', 'choice-item-'+choicesCount)
        var div = document.createElement('div')
        div.setAttribute('name','principal-block')
        div.className = 'col-12 row align-items-center flex-nowrap'
        var span = document.createElement('span')
        if (typeOfQuestion === 'multiple_choice') {
            span.className = 'fe fe-circle text-gray-500 h3 text-center col-auto q-category'
        } else if (typeOfQuestion === 'check_box') {
            span.className = 'fe fe-check-square text-gray-500 h3 text-center col-auto q-category'
        }
        var divChoice = document.createElement('div')
        divChoice.setAttribute('name', 'div-choice')
        divChoice.className = 'col-6 align-items-center flex-nowrap div-choice-block'
        var inputText = document.createElement('input')
        inputText.id = "values"
        inputText.name = "values["+index+"][text]"
        inputText.type = 'text'
        inputText.className = 'form-control me-2'
        inputText.placeholder = 'Choice ' + choicesCount
        inputText.required = true
        var buttonDelete = document.createElement('button')
        buttonDelete.id = 'remove-choice-'+choicesCount
        buttonDelete.type = 'button'
        buttonDelete.className = 'btn btn-white-20 btn-sm btn-gray btn-rounded-circle'
        buttonDelete.setAttribute('data-action', 'click->types-of-question#removeChoiceInput')
        var spanX = document.createElement('span')
        spanX.className = 'fe fe-x'
        var divCondition = document.createElement('div')
        divCondition.id = 'div-condition-'+choicesCount
        divCondition.className = 'col-5 align-items-center flex-nowrap div-condition-block'
        var selectCondition = document.createElement('select')
        selectCondition.name = "values["+index+"][condition]"
        selectCondition.className = 'form-select'

        this.conditionsValue.forEach(option => {
            const optionElement = document.createElement("option");
            optionElement.value = option[1];
            optionElement.textContent = option[0];
            selectCondition.appendChild(optionElement);
        });

        buttonDelete.appendChild(spanX)
        divChoice.appendChild(inputText)
        divCondition.appendChild(selectCondition)
        div.appendChild(span)
        div.appendChild(divChoice)
        div.appendChild(buttonDelete)
        div.appendChild(divCondition)
        li.appendChild(div)
        ulAnswers.appendChild(li)
    }

    createFreeText() {
        var choicesCount = 0
        var ulAnswers = document.getElementById(this.ulValue)
        var index = 0

        choicesCount = ulAnswers.childElementCount + 1
        index = choicesCount - 1

        var li = document.createElement('li')
        li.id = 'choice-item-'+choicesCount
        li.className = 'list-group-item'
        li.setAttribute('data-controller', 'types-of-question')
        li.setAttribute('data-types-of-question-choice-value', 'choice-item-'+choicesCount)
        var div = document.createElement('div')
        div.setAttribute('name','principal-block')
        div.className = 'col-12 row align-items-center flex-nowrap'
        var span = document.createElement('span')
        span.className = 'fe fe-edit-3 text-gray-500 h3 text-center col-auto q-category'
        var divChoice = document.createElement('div')
        divChoice.setAttribute('name', 'div-choice')
        divChoice.className = 'col-10 align-items-center flex-nowrap div-choice-block'
        var inputText = document.createElement('input')
        inputText.id = "values"
        inputText.name = "values["+index+"][text]"
        inputText.type = 'text'
        inputText.className = 'form-control me-2'
        inputText.placeholder = 'Description (optional) '
        var buttonDelete = document.createElement('button')
        buttonDelete.id = 'remove-choice-'+choicesCount
        buttonDelete.type = 'button'
        buttonDelete.className = 'btn btn-white-20 btn-sm btn-gray btn-rounded-circle'
        buttonDelete.setAttribute('data-action', 'click->types-of-question#removeChoiceInput')
        var spanX = document.createElement('span')
        spanX.className = 'fe fe-x'

        buttonDelete.appendChild(spanX)
        divChoice.appendChild(inputText)
        div.appendChild(span)
        div.appendChild(divChoice)
        div.appendChild(buttonDelete)
        li.appendChild(div)
        ulAnswers.appendChild(li)
    }

    addOneMoreChoice(event) {
        var inputType = document.getElementById(this.categoryValue)
        this.createChoice(inputType.value)
    }

    removeChoiceInput(event) {
        var choiceId = document.getElementById(this.choiceValue)
        choiceId.remove()
    }

    showEditForm(event) {

        document.getElementById(this.formIdValue).hidden = false
        document.getElementById(this.titleValue).hidden = true

        var inputType = document.getElementById(this.categoryValue)
        const btnType = document.getElementById(this.buttonValue)

        if( inputType.value === 'multiple_choice') {
            btnType.innerText = 'Multiple Choice'
        }
        else if(inputType.value === 'check_box') {
            btnType.innerText = 'Check box'
        }
        else if(inputType.value === 'free_text') {
            btnType.innerText = 'Free Text'
        }
    }
}

