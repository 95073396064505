import Swal from 'sweetalert2'
import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class extends Controller {
  static values = {
    message: String,
    url: String,
    method: String
  }

  show(event) {
    const message = this.messageValue;
    event.stopImmediatePropagation()
    event.preventDefault()
    Swal.fire({
      title: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if(result.isConfirmed) {
        get(this.urlValue, {
          responseKind: "turbo-stream"
        })
      }
    })
  }
}


