import { Controller } from "@hotwired/stimulus"

import $ from 'jquery'

export default class extends Controller {
  static values = {
    data: Object,
    check: String,
    children: String,
    form: String,
    title: String,
  }

  select(event) {
    const select_all = document.getElementById(this.checkValue).checked
    var checkboxes = document.getElementsByClassName(this.childrenValue);

    if (select_all === true) {
      for(var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
      }
    }
    else {
      for(var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  }

  showEditForm(event) {
    document.getElementById(this.formValue).hidden = false
    document.getElementById(this.titleValue).hidden = true
    $(".frm-input").focus()
  }

  newQuestion(event) {
    document.getElementById(this.formValue).hidden = false
  }

}

