import Swal from 'sweetalert2'
import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";
import { Modal } from 'bootstrap';
import $ from 'jquery'

export default class extends Controller {
  static values = {
    url: String,
    id: String,
    select: String,
    view: String
  }

  change(event) {
    let params = new URLSearchParams

    params.append('status', event.target.value)
    params.append('id', this.idValue)
    params.append('view', this.viewValue )

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
      data: params
    })
  }

  filter(event) {
    let params = new URLSearchParams

    params.append('status', event.target.value)
    params.append('id', this.idValue)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
      data: params
    })
  }
}


