import Flatpickr from 'stimulus-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import "flatpickr/dist/flatpickr.css";

export default class extends Flatpickr {

    initialize() {
        this.config = {
            allowInput: true,
            minDate: Date.now(),
            wrap: true
        }
    }

}