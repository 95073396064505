import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="search-form"
export default class extends Controller {
  static values = {
    url: String,
  }

  search(event) {
    let params = new URLSearchParams


    params.append('q', this.element.value)

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      get(`${this.urlValue}?${params}`, {
        responseKind: "turbo-stream"
      })
    }, 200);
  }
}
