import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    onClick( event ) {
        event.preventDefault()
        var input = document.getElementById("password");

        if (input.type === "password") {
            input.type = "text";
            event.target.classList.remove('fe-eye-off')
            event.target.classList.add('fe-eye')
        } else {
            input.type = "password";
            event.target.classList.remove('fe-eye')
            event.target.classList.add('fe-eye-off')
        }
    }
}

