import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import {get} from "@rails/request.js";
export default class extends Controller {
    static values = {
        url: String
    }

    click(event) {
        var id = event.target.id

        if(id === "nav-filters") {
            $("#filters-btn").attr('style', 'display: block !important');
            $("#btn-download").attr('style', 'display: none !important');
            $("#btn-export").removeAttr('hidden')
            $(".btn-insights").attr('style', 'display: none');
        }
        else if (id === "nav-insights"){
            $("#filters-btn").attr('style', 'display: none');
            $("#btn-download").attr('style', 'display: none');
            $("#btn-export").attr('hidden', true);
            $(".btn-insights").attr('style', 'display: block !important');
        }
        else if(id === "nav-settings") {
            $("#filters-btn").attr('style', 'display: none !important');
            $("#btn-download").attr('style', 'display: none !important');
            $("#btn-export").attr('hidden', true);
            $(".btn-insights").attr('style', 'display: none !important');
        }
        else if(id === "nav-reports") {
            $("#btn-download").removeAttr('hidden')
            $("#filters-btn").attr('style', 'display: none !important');
            $("#btn-download").attr('style', 'display: block !important');
            $("#btn-export").attr('hidden', true);
            $(".btn-insights").attr('style', 'display: none !important');

            get(this.urlValue, {
                responseKind: "turbo-stream"
            })
        }
    }
}

