import { Controller } from "stimulus"
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

export default class extends Controller {
  static values ={
    scorePerProfileInfo: Object,
    firstColor: String,
    secondColor: String
  }

  connect() {
    const data = this.scorePerProfileInfoValue
    var firstColor = this.firstColorValue
    var secondColor = this.secondColorValue
 
    const series = [
      {
        name: data.last_survey_year,
        data: data.last_survey,
        color: firstColor
      },
      {
        name: data.survey_year,
        data: data.survey,
        color: secondColor
      }
    ]
    Highcharts.chart(this.element, {
      chart: {
        type: 'column',
        height: 600
      },
      title: {
        text: 'Calificación Por Perfil'
      },
      xAxis: {
        type: 'category',
        categories: ['Ejecutivo', 'Empleado', 'Operario','Sin perfil']
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 25,
        title: {
          text: 'Calificación (%)'
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        },
        series: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: series,
      credits: {
        enabled: false
      }
    });
  }
}