import { Controller } from "@hotwired/stimulus"
import Sortable  from 'sortablejs'

import {get} from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    change: String
  }

  static targets = ["questions"]

  connect() {
    super.connect()

    this.sortable = Sortable.create(this.questionsTarget, {
      animation: 200,
      group: 'questions-list',
      ghostClass: 'ghost',
      onEnd: this.end.bind(this),
      onAdd: this.add.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let params = new URLSearchParams

    const questions_ids = this.sortable.toArray()
    const new_array = []

    questions_ids.forEach(function(item, index) {
      let position = index + 1
      new_array.push({item, position})
    });

    params.append('id', id)
    params.append('position', event.newIndex + 1)
    params.append('questions_ids', JSON.stringify(new_array))

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
      data: params
    })
  }

  add(event) {
    const new_section_id = event.to.id.split("questions-list-")[1]

    let id = event.item.dataset.id
    let params = new URLSearchParams

    const questions_ids = this.sortable.toArray()
    const new_array = []

    questions_ids.forEach(function(item, index) {
      let position = index + 1
      new_array.push({item, position})
    });

    params.append('id', id)
    params.append('position', event.newIndex + 1)
    params.append('questions_ids', JSON.stringify(new_array))
    params.append('new_section_id', new_section_id)

    get(`${this.changeValue}?${params}`, {
      responseKind: "turbo-stream",
      data: params
    })
  }
}