import { Controller } from "@hotwired/stimulus"
import select2 from "select2";
import $ from 'jquery'

export default class extends Controller {
  static values = {
    checkbox: String
  }
  static targets = ["select"]

  connect() {
    $(this.selectTarget).select2({
      closeOnSelect: false
    })

    var select_all = document.getElementById(this.checkboxValue)
    var id = this.selectTarget
    let event = new Event('change', { bubbles: true })

    $(this.selectTarget).select2().on('select2:select', function () {
      this.dispatchEvent(event);
      var selected =  $(id).val().length;
      var of = $(id).find('option').length;

      if (selected > 10)
        $(id).parent().find('.select2 .select2-selection ul').html('Selected ' + selected + '/ ' + of)
    }).on('select2:unselect', function () {
      select_all.checked = false
      this.dispatchEvent(event);
      var selected =  $(id).val().length;
      var of = $(id).find('option').length;

      if (selected > 10)
        $(id).parent().find('.select2 .select2-selection ul').html('Selected ' + selected + '/ ' + of)
    })
  }
}

