import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    name: String,
  }
  print(event) {
    var date = new Date()
    var reportDiv = document.getElementById('tables').innerHTML
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = reportDiv;
    document.title = this.nameValue+' report'
    window.print(); //scale:75 horizontal
    document.body.innerHTML = originalContents;
  }
}

