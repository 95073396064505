import { Controller } from "@hotwired/stimulus"
import select2 from "select2";
import $ from 'jquery'

export default class extends Controller {

  clear(event) {
    event.preventDefault()
    $('select').select2().val('').trigger('change');

    document.getElementById("select_all").checked = false;
    $('select').select2({
      closeOnSelect: false
    })
  }
}

