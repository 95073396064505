import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        firstColor: String,
        secondColor: String,
        thirdColor: String,
        fourthColor: String,
        fifthColor: String,
        sixthColor: String,
    }

    connect() {
        var totalMaxElement = document.getElementsByClassName('average-report-column average-maximus total-max')
        var totalMinElement = document.getElementsByClassName('average-report-column average-minimum total-min')
        var minValueElement = document.getElementsByClassName('min-value')

        for(var i = 0; i < totalMaxElement.length; i++) {
            totalMaxElement[i].style.backgroundColor = this.thirdColorValue
            totalMaxElement[i].style.color = this.fourthColorValue
        }

        for(var j = 0; j < totalMinElement.length; j++) {
            totalMinElement[j].style.backgroundColor = this.secondColorValue
            totalMinElement[j].style.color = this.fifthColorValue
        }

        for(var k = 0; k < minValueElement.length; k++) {
            minValueElement[k].style.color = this.secondColorValue
        }

        //PDF Style
        var reportCover = document.getElementsByClassName('d-flex flex-column align-items-center justify-content-center report-cover')
        var reportHRLine = document.getElementsByClassName('report-hr-red')
        var headline = document.getElementsByClassName('headline')
        var companyName = document.getElementsByClassName('report-subtitle')

        for(var l = 0; l < reportCover.length; l++) {
            reportCover[l].style.backgroundColor = this.sixthColorValue
            reportCover[l].style.color = this.fifthColorValue
        }

        for(var m = 0; l < reportHRLine.length; m++) {
            reportHRLine[m].style.border = this.sixthColorValue
            reportHRLine[m].style.backgroundColor = this.sixthColorValue
        }

        for(var n = 0; n < headline.length; n++) {
            headline[n].style.borderLeft = "10px solid "+this.sixthColorValue
            headline[n].style.backgroundColor = this.fifthColorValue
        }

        for(var o = 0; o < companyName.length; o++) {
            companyName[o].style.color = "10px solid "+this.thirdColorValue
        }
   }
}

