import { Controller } from "@hotwired/stimulus"
import select2 from "select2";
import $ from 'jquery'

export default class extends Controller {
    static values = {
        url: String,
        data: Array
    }

    connect() {
        let event = new Event('change', { bubbles: true })
        let countedSelectedItems =  this.countedItems

        $('#branch_office_id').on('select2:close', function () {
            this.dispatchEvent(event);
            countedSelectedItems()
        }).on('select2:unselect', function () {
            var select_all = document.getElementById("select_all_bo")
            select_all.checked = false
            this.dispatchEvent(event);
            countedSelectedItems()
        })

        $("#branch_office_id").select2({
            closeOnSelect: false
        })

        $("#department_id").select2({
            closeOnSelect: false,
            data: this.dataValue
        })

        $(document).on("click", ".select_all_dep", function () {

            var groupName = $(this).html()
            var options = $('#department_id option');

            $.each(options, function (key, value) {
                if ($(value)[0].parentElement.label.indexOf(groupName) >= 0) {
                    $(value).prop("selected", "selected");
                }
            });
        });

        $('#department_id').on('change', function () {
            var selected = $(this).val().length;
            var of = $(this).find('option').length;

            if (selected > 10)
                $(this).parent().find('.select2 .select2-selection ul').html('Selected ' + selected + ' / ' + of)
        })
    }
    change() {
        let countedSelectedItems =  this.countedItems

        let params = new URLSearchParams
        $('#branch_office_id').select2('data').forEach(function(item, index) {
            params.append('branch_office_id[]', parseInt(item.id))
        });

        let selected_departments = []
        $('#department_id').select2('data').forEach(function(item, index) {
            selected_departments.push(item.id)
        });

        if (params.size > 0) {
            $.ajax({
                type: "GET",
                url: this.urlValue + '?' + params,
                dataType: "json",
                success: function (data) {
                    $('#department_id').html('').select2('data', null)
                    $("#select_all_dep").prop("checked", true)
                    $("#department_id").select2({
                        data: data
                    })
                    $("#department_id").select2().val(selected_departments).trigger('change');

                    if ($("#select_all_dep").prop("checked")){
                        var arr = [];
                        for (var i in data) {
                            var children = data[i].children
                            for (var j in children) {
                                arr.push(children[j].id);
                            }
                        }
                        $("#department_id").select2().val(arr).trigger('change');
                    }

                    $(document).on("click", ".select_all_dep", function () {

                        var groupName = $("#branch_office_id").html()
                        var options = $('#department_id option');

                        $.each(options, function (key, value) {
                            if ($(value)[0].parentElement.label.indexOf(groupName) >= 0) {
                                $(value).prop("selected", "selected");
                            }
                        });
                        countedSelectedItems()
                    });

                    $('#department_id').on('change', function () {
                        var selected = $(this).val().length;
                        var of = $(this).find('option').length;

                        if (selected > 10)
                            $(this).parent().find('.select2 .select2-selection ul').html('Selected ' + selected + ' / ' + of)
                    });

                    $("#department_id").select2({
                        closeOnSelect: false
                    })
                    countedSelectedItems()
                }
            });
        }
        else {
            $('#department_id').html('').select2('data', null )
        }
    }

    countedItems() {
        var selected_bo =  $("#branch_office_id").val().length;
        var of_bo = $("#branch_office_id").find('option').length;

        if (selected_bo > 10)
            $("#branch_office_id").parent().find('.select2 .select2-selection ul').html('Selected ' + selected_bo + '/ ' + of_bo)

        var selected =  $("#department_id").val().length;
        var of = $("#department_id").find('option').length;

        if (selected > 10)
            $("#department_id").parent().find('.select2 .select2-selection ul').html('Selected ' + selected + '/ ' + of)
    }
}