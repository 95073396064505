
import { Controller } from "stimulus"
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);


export default class extends Controller {
  static values = {
    data: Object,
    title: String,
    chartWidth: String,
    firstColor: String,
    secondColor: String
  }

  connect() {
    const data = this.dataValue
    const title = this.titleValue
    const categories = data.sections
    var firstColor = this.firstColorValue
    var secondColor = this.secondColorValue

    const series = [
      {
        name: data.last_survey_year,
        data: data.last_survey,
        color: firstColor
      },
      {
        name: data.survey_year,
        data: data.survey,
        color: secondColor
      }
   ]

   let chartWidth = this.chartWidthValue || null

    Highcharts.chart(this.element, {
      chart: {
        type: 'column',
        height: 600,
        width: chartWidth
      },
      title: {
        text: title
      },
      xAxis: {
        type: 'category',
        categories: categories
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 25,
        title: {
          text: 'Calificación (%)'
        }
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        },
        series: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '8px'
            }
          }
        }
      },
      series: series,
      credits: {
        enabled: false
      }
    });
  }
}
