// welcome_message_controller.js
import { Controller } from "stimulus"
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['messageContainer']

  connect() {
    let welcomeMessageModal = new Modal(this.messageContainerTarget);
    welcomeMessageModal.show();
  }
}
