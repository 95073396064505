// range_slider_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['toast']

  connect() {
    setTimeout(() => this.dismiss(), 10000);
  }

  dismiss() {
    this.toastTarget.classList.remove('animate__bounceInRight');
    this.toastTarget.classList.add('animate__bounceOutRight');
  }
}