import { Controller } from "@hotwired/stimulus"
import DataTable from 'datatables.net-bs5';

import $ from 'jquery'

export default class extends Controller {

  static targets = ["table"]

  connect() {
    $(this.tableTarget).DataTable()
  }
}

